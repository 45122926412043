import { environment } from '../environments/environment';
import { Injectable } from "@angular/core";

@Injectable()
export class Config {
    public get teamsVisitsApiBaseUrl(): string {
        return environment.baseUrl.teamVisits;
    }

    public get nflTeamsApiBaseUrl(): string {
        return environment.baseUrl.nflTeams;
    }

}