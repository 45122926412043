<ng-container *ngIf="combinedObs$ | async as teams; else loading">
  <h3 class='mb-2 mb-md-5 mx-auto text-center w-full'>
    Fields Touched: {{totalTeamsVisited}} / 32
  </h3>
  <div class="grid">
    <div *ngFor="let team of teams">
      <jois-team [teamID]="team.id"
                 [code]="team.abbreviation"
                 [name]="team.displayName"
                 [stadium]="null"
                 [visitedDate]="team.date"
                 [primaryColor]="generateColor(team.color)"
                 [secondaryColor]="generateAlternateColor(team)"
                 [logoHref]="getTeamLogoHref(team)"
                 [orientation]="team.orientation"
                 [thumbnailUri]="team.thumbnailUri"
                 [fullPhotoUri]="team.fullPhotoUri">
      </jois-team>
    </div>
  </div>
</ng-container>

<ng-template #loading>
  <jois-loader></jois-loader>
</ng-template>