<div class="team-container"
     [ngStyle]="{'border-color': secondaryColor, 'background': primaryColor, 'opacity': (visitedDate ? 1 : 0.3)}"
     [ngClass]="{'visited': visitedDate}"
     (click)="openModal()">
     <div class="thumbnail"
          [ngStyle]="{'background-image': getBackgroundUrl(thumbnailUri)}">
     </div>
     <div class="bottom">
          <span [ngStyle]="{'color': secondaryColor}"
                class="text-center align-middle">{{code | uppercase}}</span>
          <img [src]="logoHref"
               class="logo">
     </div>
</div>