import { Component, OnInit, Input } from '@angular/core';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';
import { NflTeamsService } from '../nfl-teams/nfl-teams.service';

@Component({
  selector: 'jois-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  @Input()
  teamID: string;

  @Input()
  code: string;

  @Input()
  name: string;

  @Input()
  stadium: string;

  @Input()
  visitedDate: string;

  @Input()
  logoHref: string;

  @Input()
  primaryColor: string;

  @Input()
  secondaryColor: string;

  @Input()
  orientation: string;

  @Input()
  thumbnailUri: string;

  @Input()
  fullPhotoUri: string;

  constructor(
    private nflTeamsService: NflTeamsService,
    private lightBox: Lightbox,
    private lightBoxConfig: LightboxConfig
  ) {
    lightBoxConfig.centerVertically = true;
    lightBoxConfig.disableScrolling = true;
    lightBoxConfig.fadeDuration = 0.1;
    lightBoxConfig.resizeDuration = 0.1;
  }

  ngOnInit() { }

  openModal() {
    if (!this.visitedDate) {
      return;
    }

    this.nflTeamsService.getTeamDetails(this.teamID).subscribe(teamDetail => {
      const caption =
        this.name + "&nbsp&nbsp|&nbsp&nbsp" + teamDetail.location + "<br>" +
        new Date(this.visitedDate).toDateString();

      const albums = [
        {
          src: this.fullPhotoUri,
          caption: caption,
          thumb: this.thumbnailUri
        }
      ];
      this.lightBox.open(albums, 0);
    })
  }

  getBackgroundUrl(thumbnailUri): string {
    return `url('${thumbnailUri}')`;
  }
}
