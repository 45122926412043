import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { LightboxModule } from 'ngx-lightbox';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NflTeamsService } from './nfl-teams/nfl-teams.service';
import { TeamComponent } from './team/team.component';
import { TeamContainerComponent } from './team-container/team-container.component';
import { TeamVisitService } from './team-visit/team-visit.service';
import { Config } from './config';
import { LoaderComponent } from './shared/components/loader/loader.component';

@NgModule({
  declarations: [
    AppComponent,
    TeamComponent,
    TeamContainerComponent,
    LoaderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    LightboxModule
  ],
  providers: [
    Config,
    NflTeamsService,
    TeamVisitService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }