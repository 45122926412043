import { Component, OnInit } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NflTeamsService } from '../nfl-teams/nfl-teams.service';
import { Team } from '../nfl-teams/nfl.model';
import { TeamVisit } from '../team-visit/team-visit.model';
import { TeamVisitService } from '../team-visit/team-visit.service';

@Component({
  selector: 'jois-team-container',
  templateUrl: './team-container.component.html',
  styleUrls: ['./team-container.component.scss']
})
export class TeamContainerComponent implements OnInit {

  combinedObs$: Observable<(Team & TeamVisit)[]>;

  totalNflTeams: number;
  totalTeamsVisited: number;

  constructor(
    private nflTeamsService: NflTeamsService,
    private teamVisitsService: TeamVisitService
  ) { }

  ngOnInit() {
    const teams$ = this.nflTeamsService.getTeams();
    const teamVisits$ = this.teamVisitsService.getTeamsVisited();
    this.combinedObs$ = forkJoin([teams$, teamVisits$])
      .pipe(
        map((result) => {
          const nflTeams = result[0];
          const visitedTeams = result[1];

          this.totalNflTeams = nflTeams.length;
          this.totalTeamsVisited = visitedTeams.length;

          return nflTeams.map(nflTeam => ({
            ...visitedTeams.find(visitedTeam => visitedTeam?.team.toLowerCase() === nflTeam.abbreviation.toLowerCase()),
            ...nflTeam
          }));
        })
      )
  }

  getTeamLogoHref(team: Team): string {
    return this.nflTeamsService.getTeamLogo(team).href;
  }

  generateColor(colorCode: string): string {
    return `#${colorCode}`;
  }

  generateAlternateColor(team: Team): string {
    return team.color === team.alternateColor ? 'white' : this.generateColor(team.alternateColor);
  }
}
