import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Config } from '../config';
import { TeamVisit } from './team-visit.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TeamVisitService {

  constructor(
    private config: Config,
    private http: HttpClient
  ) { }

  public getTeamsVisited(): Observable<TeamVisit[]> {
    return this.http.get<TeamVisit[]>(`${this.config.teamsVisitsApiBaseUrl}/teamVisits.json`);
  }
}
