import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Team, TeamDetails, TeamLogo } from './nfl.model';
import { Config } from '../config';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NflTeamsService {

  private readonly API_URL = "apis/site/v2/sports/football/nfl/teams";

  constructor(
    private http: HttpClient,
    private config: Config
  ) { }

  public getTeams(): Observable<Team[]> {
    return this.http.get(`${this.config.nflTeamsApiBaseUrl}/${this.API_URL}`)
      .pipe(
        map((result: any) => result.sports[0].leagues[0].teams),
        map((teams: any[]) => teams.map(team => team.team)),
      );
  }

  public getTeamDetails(id: string): Observable<TeamDetails> {
    return this.http.get(`${this.config.nflTeamsApiBaseUrl}/${this.API_URL}/${id}`)
      .pipe(
        map((result: any) => result.team)
      );
  }

  public getTeamLogo(team: Team): TeamLogo {
    return team.logos.find(logo => logo.rel.some(rel => rel === "dark"));
  }
}
